import React from 'react';

import { useHasPermission } from '~/shared/acl/hooks';

/**
 * @param {Array<string>} needPermissions
 * @param {React.Node} children
 * @param {React.Node} renderWithoutAccess
 * @returns {JSX.Element|*}
 * @constructor
 */
export const AccessControl = ({ needPermissions, children, renderWithoutAccess = null }) => {
  const hasPermission = useHasPermission(needPermissions);
  const renderNoAccess = () => (renderWithoutAccess ? renderWithoutAccess : <></>);

  return hasPermission() ? <>{children}</> : renderNoAccess();
};
