import React, { useCallback } from 'react';

import { useJWTStorage } from '~/processes/auth';
import { IS_BUYER_ROLE, IS_SELLER_ROLE } from '~/processes/auth/permissions';

/**
 * @param {[string]} userPermissions
 * @param {[string]} needPermissions
 * @param {boolean?} strict
 * @returns {boolean}
 */
const checkPermissions = ({ userPermissions, needPermissions, strict = false }) => {
  if (needPermissions.length === 0) return true;
  const setPermissions = new Set(userPermissions);
  const _intersection = new Set();
  for (const elem of needPermissions) {
    if (!setPermissions.has(elem)) continue;
    if (strict) {
      _intersection.add(elem);
      continue;
    }
    return setPermissions.has(elem);
  }
  return _intersection.size > 0;
};

/**
 * @param {Array.<string>} needPermissions
 * @param {boolean?} strict
 * @returns {function}
 */
export const useHasPermission = (needPermissions = [], strict = false) => {
  const { getUserPermissions, getJwt } = useJWTStorage();

  const jwt = getJwt();

  return useCallback(
    () => checkPermissions({ userPermissions: getUserPermissions(), needPermissions, strict }),
    [needPermissions, jwt]
  );
};

/**
 * @returns {function(): boolean|boolean}
 */
export const useBuyerAccess = () => useHasPermission([IS_BUYER_ROLE]);

/**
 * @returns {function(): boolean|boolean}
 */
export const useSellerAccess = () => useHasPermission([IS_SELLER_ROLE]);

/**
 *
 * @returns {{prefix: (string)}}
 */
export const useWithUserApiPrefix = () => {
  const isBuyerInvoke = useBuyerAccess();
  return { prefix: isBuyerInvoke() ? 'buyer' : 'seller' };
};
