import React, { useCallback } from 'react';

import { Container } from '~/shared/components';
import clsx from 'clsx';

export const Tips = ({ tips = [] }) => {
  const colors = ['purple', 'yellow', 'red', 'pink', 'blue', 'green'];

  const hashString = useCallback(
    (string) =>
      string
        .split('')
        .map((char) => char.charCodeAt(0))
        .reduce((a, b) => a + b, 0),
    []
  );

  const getColor = useCallback((label) => colors[hashString(label) % colors.length], [tips]);
  return (
    <>
      {tips.map((tip, index) => (
        <Container key={tip} className={clsx('tip', `tip-${getColor(tip)}`)}>
          {tip}
        </Container>
      ))}
    </>
  );
};
