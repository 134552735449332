import React, { useCallback, useEffect, useState } from 'react';

import { Container } from '~/shared/components';
import { Portal } from '~/shared/components/portal/ui/portal';
import { emitEvent, events } from '~/shared/events';
import { useGlobalEvents } from '~/shared/events/useGlobalEvents';
import { useEvent } from '~/shared/hooks';
import { NotificationTile } from '~/widgets/notification/ui/notification.tile';

export const NotificationBlock = ({ className, elementTo }) => {
  const [notifications, setNotifications] = useState([]);

  const clearNotification = useEvent((key) => {
    const toHide = notifications.find((notification) => notification.key === key);
    if (!!toHide) {
      toHide.show = false;
      setNotifications((prevState) => [...prevState, toHide]);
    }
    setTimeout(() => {
      setNotifications((prevState) => [...prevState.filter((notification) => notification.key !== key)]);
    }, 1000);
  });

  const sendNotification = useCallback(({ detail: { variant, title, subtitle } }) => {
    setNotifications((prevState) => [
      ...prevState,
      { variant, title, subtitle, key: `${Date.now()}_${Math.random()}`, show: true },
    ]);
  }, []);

  useGlobalEvents([{ event: events.sendNotification, action: sendNotification }]);

  return (
    <Portal elementTo={elementTo}>
      <Container className={className}>
        {notifications.map((notification) => (
          <NotificationTile
            key={notification.key}
            variant={notification.variant}
            subtitle={notification.subtitle}
            notificationKey={notification.key}
            title={notification.title}
            show={notification.show}
            onShowComplete={clearNotification}
          />
        ))}
      </Container>
    </Portal>
  );
};
