import React, { useEffect } from 'react';

import { subscribe, unsubscribe } from '~/shared/events/index';

/**
 * @typedef {Object} EventSubscribers
 * @property {string} event
 * @property {function} action
 */

/**
 * @param {[EventSubscribers]} subscriptions
 */
export const useGlobalEvents = (subscriptions = []) => {
  useEffect(() => {
    subscriptions.forEach((subscription) => subscribe(subscription.event, subscription.action));
    return () => subscriptions.forEach((subscription) => unsubscribe(subscription.event, subscription.action));
  }, []);
};
