import { useEffect, useId, useState } from 'react';

import debounce from 'lodash.debounce';

export const useClickOutside = ({ elementRef }) => {
  const [action, setAction] = useState(() => () => {});
  const id = useId();

  const behaviour = (event) => {
    if (!elementRef?.current?.contains(event.target)) {
      action();
    }
  };

  useEffect(() => {
    if (typeof window === `undefined`) return;
    window.addEventListener('mousedown', behaviour);
    return () => {
      window.removeEventListener('mousedown', behaviour);
    };
  }, [action]);

  return { setAction };
};
