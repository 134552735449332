import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

export const Portal = ({ elementTo, children, after = false, className = 'portal' }) => {
  const [container] = useState(() => document.createElement('div'));

  useEffect(() => {
    className ? container.classList.add(className) : null;
    if (elementTo) {
      after ? elementTo.appendChild(container) : elementTo.prepend(container);
      return () => elementTo.removeChild(container);
    }
  }, [elementTo]);

  return ReactDOM.createPortal(children, container);
};
