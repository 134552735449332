import Backpack from '~/assets/images/icons/backpack.svg';
import Bookmark from '~/assets/images/icons/bookmark.svg';
import BookmarkActive from '~/assets/images/icons/bookmark_active.svg';
import ChevronDown from '~/assets/images/icons/chevron-down.svg';
import ChevronLeft from '~/assets/images/icons/chevron-left.svg';
import ChevronBack from '~/assets/images/icons/chevron-left-bold.svg';
import ChevronUp from '~/assets/images/icons/chevron-up.svg';
import ChipsClose from '~/assets/images/icons/chips-close.svg';
import CloseSearch from '~/assets/images/icons/close-search.svg';
import Cross from '~/assets/images/icons/cross.svg';
import ChevronLeftDouble from '~/assets/images/icons/doble-chevrons-left.svg';
import ChevronRightDouble from '~/assets/images/icons/doble-chevrons-right.svg';
import Download from '~/assets/images/icons/download.svg';
import Exit from '~/assets/images/icons/exit.svg';
import Eye from '~/assets/images/icons/eye.svg';
import EyeCross from '~/assets/images/icons/eye-cross.svg';
import File from '~/assets/images/icons/file.svg';
import FilterActive from '~/assets/images/icons/filter.active.svg';
import Filter from '~/assets/images/icons/filter.svg';
import Gear from '~/assets/images/icons/gear.svg';
import Home from '~/assets/images/icons/home.svg';
import NotificationError from '~/assets/images/icons/notification.error.svg';
import NotificationInfo from '~/assets/images/icons/notification.info.svg';
import NotificationSuccess from '~/assets/images/icons/notification.success.svg';
import Person from '~/assets/images/icons/person.svg';
import Pin from '~/assets/images/icons/pin.svg';
import PlusCircled from '~/assets/images/icons/plus-circled.svg';
import QuestionMark from '~/assets/images/icons/question-mark.svg';
import Reader from '~/assets/images/icons/reader.svg';
import Search from '~/assets/images/icons/search.svg';
import SearchTablet from '~/assets/images/icons/search-tablet.svg';
import SortIcon from '~/assets/images/icons/sort-icon.svg';
import TableIcon from '~/assets/images/icons/table-icon.svg';
import TileBookmark from '~/assets/images/icons/tile-bookmark.svg';
import Tokens from '~/assets/images/icons/tokens.svg';
import Triangle from '~/assets/images/icons/triangle.svg';
import Verified from '~/assets/images/icons/verified.svg';
import ViewGrid from '~/assets/images/icons/view-grid.svg';
import ViewTable from '~/assets/images/icons/view-table.svg';

export const icons = {
  Eye,
  EyeCross,
  Pin,
  Backpack,
  Bookmark,
  BookmarkActive,
  TileBookmark,
  Exit,
  Gear,
  Home,
  Person,
  QuestionMark,
  Reader,
  Tokens,
  Triangle,
  PlusCircled,
  ChipsClose,
  NotificationError,
  NotificationInfo,
  NotificationSuccess,
  Search,
  Filter,
  FilterActive,
  ChevronLeft,
  ChevronBack,
  ChevronLeftDouble,
  ChevronRightDouble,
  ChevronDown,
  ChevronUp,
  File,
  Download,
  CloseSearch,
  SearchTablet,
  Cross,
  Verified,
  TableIcon,
  ViewGrid,
  ViewTable,
  SortIcon,
};
