import React, { useMemo } from 'react';

import { AccessControl } from '~/shared/acl';
import { Container } from '~/shared/components';
import { NavigationGuard } from '~/shared/guards';
import clsx from 'clsx';
import { Link } from 'gatsby';

/**
 *
 * @param {MenuItem} item
 * @param className
 * @param activeClassName
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
const MenuItem = ({ item, className = 'menu__item', activeClassName = 'active', ...rest }) => (
  <Link
    to={item.to}
    getProps={({ isCurrent, isPartiallyCurrent }) => ({
      className: clsx(className, isCurrent && activeClassName, isPartiallyCurrent && activeClassName),
    })}
    {...rest}
  >
    {item?.icon ? <img alt={item.label} className="menu__icon" src={item?.icon} /> : <></>}
    {item.label}
  </Link>
);

const MenuItemDisabled = React.memo(({ ...props }) => <MenuItem {...props} className={'menu__item disabled'} />);

/**
 * @typedef {Object} MenuItem
 * @property {string} to
 * @property {string} label
 * @property {React.ReactNode} icon
 * @property {[string]} permissions
 * @property {function?} action
 * @property {[string]} noFullPermissions
 */

/**
 * @param {[MenuItem]} items
 * @returns {JSX.Element}
 * @constructor
 */
// eslint-disable-next-line react/display-name
export const MenuItems = React.memo(({ items }) => {
  const menuItems = useMemo(
    () =>
      items.map((item) => (
        <NavigationGuard key={item.label} permissions={item.permissions}>
          <AccessControl
            renderWithoutAccess={<MenuItemDisabled item={item} />}
            needPermissions={item?.noFullPermissions || []}
          >
            <MenuItem item={item} />
          </AccessControl>
        </NavigationGuard>
      )),
    [items]
  );
  return <Container className="menu__list">{menuItems}</Container>;
});
