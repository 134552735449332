import React, { useMemo } from 'react';

import logotype from '~/assets/images/logo.svg';
import { applicationSettings } from '~/shared/application.settings';
import { HeaderLink } from '~/shared/components';
import { Image } from '~/shared/components/ui/image';

/**
 *
 * @param {string} className
 * @param {string} logo
 * @param {boolean} isLoggedIn
 * @returns {JSX.Element}
 * @constructor
 */
// eslint-disable-next-line react/display-name
export const PublicHeader = React.memo(({ className = 'header', logo = logotype, isLoggedIn = false }) => {
  const renderHeader = useMemo(() => {
    return (
      <>
        {!isLoggedIn ? (
          <div>
            <HeaderLink to={'/auth/sign-in'}>Sign in</HeaderLink>
            {applicationSettings.SHOW_SIGN_UP ? <HeaderLink to={'/auth/sign-up'}>Sign up</HeaderLink> : <></>}
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }, [isLoggedIn]);
  return (
    <header className={className}>
      <Image src={logo} alt="10xbuycell" height={27} className={'menu__logo'} />
      {renderHeader}
    </header>
  );
});
