import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { ADD_LOADER, REMOVE_LOADER, useLoading } from '~/app/providers/loaderProvider';
import { useJWTStorage } from '~/processes/auth';
import { useSellerAccess } from '~/shared/acl';
import { emitEvent } from '~/shared/events';
import { events } from '~/shared/events/handlers/common/events';
import { useGlobalEvents } from '~/shared/events/useGlobalEvents';
import { navigate } from 'gatsby';

export const CommonSubscribers = () => {
  const { dispatch } = useLoading();
  const { deleteJwt } = useJWTStorage();
  const isSellerInvoke = useSellerAccess();
  const queryClient = useQueryClient();

  const authComplete = () => {
    emitEvent(events.startLoaderEvent);
    isSellerInvoke() ? emitEvent(events.navigateToEvent, '/seller') : emitEvent(events.navigateToEvent, '/buyer');
    emitEvent(events.endLoaderEvent);
  };

  const navigateTo = ({ detail: path }) => navigate(path);

  const logout = async () => {
    deleteJwt();
    emitEvent(events.navigateToEvent, '/auth/sign-in');
  };

  const startLoader = () => dispatch({ type: ADD_LOADER });
  const endLoader = () => dispatch({ type: REMOVE_LOADER });
  const raisePermissionDenied = () => emitEvent(events.navigateToEvent, '/forbidden');
  const notFoundHandler = () => emitEvent(events.navigateToEvent, '/404');
  const byPassUser = () => emitEvent(events.navigateToEvent, '/404');
  const sendNotification = ({ variant, title, subtitle }) =>
    emitEvent(events.sendNotification, { variant, title, subtitle });

  useGlobalEvents([
    { event: events.authCompleteEvent, action: authComplete },
    { event: events.navigateToEvent, action: navigateTo },
    { event: events.logoutEvent, action: logout },
    { event: events.startLoaderEvent, action: startLoader },
    { event: events.endLoaderEvent, action: endLoader },
    { event: events.permissionDenied, action: raisePermissionDenied },
    { event: events.notFoundEvent, action: notFoundHandler },
    { event: events.byPassUserEvent, action: byPassUser },
  ]);
};
