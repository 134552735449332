import React from 'react';
import { CookiesProvider } from 'react-cookie';

import '~/assets/styles/global.scss';
import { AuthProvider, ConfigProvider, LayoutProvider, LoaderProvider, QueryProvider } from '~/app/providers';
import { QueryParamProvider } from 'use-query-params';
import { ReachAdapter } from 'use-query-params/adapters/reach';
export const wrapRootElement = ({ element }) => (
  <QueryParamProvider adapter={ReachAdapter}>
    <QueryProvider>
      <LoaderProvider>
        <ConfigProvider>
          <CookiesProvider>
            <AuthProvider>
              <LayoutProvider>{element}</LayoutProvider>
            </AuthProvider>
          </CookiesProvider>
        </ConfigProvider>
      </LoaderProvider>
    </QueryProvider>
  </QueryParamProvider>
);
