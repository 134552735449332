import React, { useEffect, useRef } from 'react';

import { useLoading } from '~/app/providers/loaderProvider';
import { useLoadLookups } from '~/entities/lookups';
import { useAuth } from '~/processes/auth';
import { LoadingSpinner } from '~/shared/components/spinner';
import { CommonSubscribers, emitEvent, events } from '~/shared/events';
import { PublicHeader } from '~/widgets/header';
import { NotificationBlock } from '~/widgets/notification';

export const PublicLayout = ({ children }) => {
  const { showLoader } = useLoading();
  const contentRef = useRef(null);

  useLoadLookups();

  useEffect(() => emitEvent(events.endLoaderEvent), []);
  const { isLoggedIn } = useAuth();
  return (
    <>
      <div ref={contentRef}></div>
      <CommonSubscribers />
      {showLoader ? (
        <LoadingSpinner fullPage={true} />
      ) : (
        <>
          <PublicHeader isLoggedIn={isLoggedIn} />
          <main>{children}</main>
          <NotificationBlock elementTo={contentRef.current} className={'notification-list notification-list-public'} />
        </>
      )}
    </>
  );
};
