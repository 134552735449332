import React from 'react';

import { Container } from '~/shared/components';
import { Heading } from '~/shared/components/typography/typography.heading';

/**
 * @param {React.ReactNode} MenuItems
 * @param {Account} accountInfo
 * @returns {JSX.Element}
 * @constructor
 */
// eslint-disable-next-line react/display-name
export const MenuBody = React.forwardRef(({ menuItems: MenuItems, accountInfo }, ref) => (
  <Container ref={ref} className="menu__body">
    <MenuAccountInfo accountInfo={accountInfo} />
    {MenuItems ? MenuItems : <></>}
  </Container>
));

const MenuAccountInfo = ({ accountInfo }) =>
  accountInfo ? (
    <Container className="menu__info">
      <Heading variant={4} className="menu__name">
        {accountInfo.firstName} {accountInfo.lastName}
      </Heading>
      <p className="menu__email">{accountInfo.email}</p>
    </Container>
  ) : (
    <></>
  );
