import React, { useEffect, useMemo } from 'react';

import { Container, icons } from '~/shared/components';
import { Heading } from '~/shared/components/typography/typography.heading';
import { Image } from '~/shared/components/ui/image';
import clsx from 'clsx';

const iconsMapped = {
  error: icons.NotificationError,
  info: icons.NotificationInfo,
  success: icons.NotificationSuccess,
};

/**
 *
 * @param {number} notificationKey
 * @param {('info'|'success'|'error')} variant
 * @param {string} title
 * @param {string} subtitle
 * @param {boolean} show
 * @param {function} onShowComplete
 * @param {number} showTime
 * @returns {JSX.Element}
 * @constructor
 */
export const NotificationTile = ({
  notificationKey,
  variant,
  title,
  subtitle,
  show = false,
  onShowComplete,
  showTime = 5000,
}) => {
  const Icon = useMemo(() => iconsMapped?.[variant] || <></>, [variant]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      onShowComplete(notificationKey);
    }, showTime);
    return () => clearTimeout(timeOut);
  }, []);

  return (
    <Container className={clsx('notification', `notification-${variant}`, show && 'notification-show')}>
      <Heading variant={4} className="notification__title">
        <Image src={Icon} className={'notification__icon'} />
        {title}
      </Heading>
      <p className="notification__subtitle">{subtitle}</p>
    </Container>
  );
};
