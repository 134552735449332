export const IS_SELLER_ROLE = 'is_seller';
export const IS_BUYER_ROLE = 'is_buyer';

export const CAN_EDIT_PROFILE = 'can_edit_profile';
export const CAN_RESET_PASSWORD = 'can_reset_password';
export const CAN_VIEW_LISTING = 'can_view_listing';
export const CAN_LIST_LISTING = 'can_list_listing';
export const CAN_ALLOW_LISTING = 'can_allow_listing';
export const CAN_VIEW_PROSPECTS = 'can_view_prospects';
export const CAN_VIEW_PROSPECT_DETAIL = 'can_view_prospect_detail';
export const CAN_CREATE_LISTING = 'can_create_listing';
export const CAN_BUY = 'can_buy';
