import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { getLookups } from '~/entities/lookups/model';
import { emitEvent, events } from '~/shared/events';
import { useDictionaryConfig } from '~/shared/hooks';

export const useLoadLookups = () => {
  const { addManyToConfig } = useDictionaryConfig();
  const { data: lookups, isLoading } = useQuery(['lookups'], getLookups, {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => emitEvent(events.startLoaderEvent), []);
  useEffect(() => {
    if (!isLoading) {
      emitEvent(events.endLoaderEvent);
    }
  }, [isLoading]);

  if (!!lookups) {
    addManyToConfig(lookups?.dictionaries || {});
  }
};
