const authCompleteEvent = Symbol.for('authComplete').toString();
const navigateToEvent = Symbol.for('navigateTo').toString();
const logoutEvent = Symbol.for('logout').toString();
const startLoaderEvent = Symbol.for('startLoader').toString();
const endLoaderEvent = Symbol.for('endLoader').toString();
const permissionDenied = Symbol.for('permissionDenied').toString();
const notFoundEvent = Symbol.for('notFound').toString();
const byPassUserEvent = Symbol.for('byPassUser').toString();
const sendNotification = Symbol.for('sendNotification').toString();
const changeLayoutMode = Symbol.for('changeLayoutMode').toString();

export const events = {
  authCompleteEvent,
  navigateToEvent,
  logoutEvent,
  startLoaderEvent,
  endLoaderEvent,
  permissionDenied,
  notFoundEvent,
  byPassUserEvent,
  sendNotification,
  changeLayoutMode,
};
