import React from 'react';

import { Container } from '~/shared/components';
import clsx from 'clsx';

/**
 *
 * @param {React.ReactNode} Header
 * @param {React.ReactNode} Body
 * @param {React.ReactNode} Footer
 * @param {string} className
 * @constructor
 */
export const MenuContainer = ({
  header: Header = null,
  body: Body = null,
  footer: Footer = null,
  className = 'menu',
}) => {
  return (
    <Container className={className}>
      {Header ? Header : <></>}
      <Container className={clsx('menu__content')}>
        {Body ? Body : <></>}
        {Footer ? Footer : <></>}
      </Container>
    </Container>
  );
};
