import { api } from '~/shared/api';

export const refreshTokenAction = async ({ token }) => {
  try {
    return await api.post('/auth/token/refresh-with-permissions/', { refresh: token });
  } catch (e) {
    console.warn(e);
    return null;
  }
};
