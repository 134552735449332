import React, { Component, useEffect } from 'react';

import { useAuth } from '~/processes/auth';
import { emitEvent } from '~/shared/events';
import { events } from '~/shared/events/handlers/common/events';

/**
 * @param {React.ReactNode} Component
 * @param isAllowed
 * @param {React.ReactNode} children
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const PrivateGuard = ({ component: Component, isAllowed = false, children, ...props }) => {
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (!isLoggedIn) {
      emitEvent(events.logoutEvent);
      return;
    }
    if (!isAllowed) {
      emitEvent(events.permissionDenied);
    }
  }, []);

  return <Component {...props}>{children}</Component>;
};

/**
 * @param {React.ReactNode} Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const PublicGuard = ({ component: Component, ...props }) => {
  const { isLoggedIn, getUserPermissions } = useAuth();

  useEffect(() => {
    if (isLoggedIn) {
      emitEvent(events.authCompleteEvent, getUserPermissions());
    }
  }, []);

  return <Component {...props} />;
};
