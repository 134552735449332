import { useContext, useEffect } from 'react';

import { LayoutContext } from '~/app/providers/layoutProvider';

export const useSearch = (searchEngine) => {
  const { search, setSearch } = useContext(LayoutContext);

  useEffect(() => {
    setSearch(searchEngine);
    return () => setSearch(null);
  }, []);

  return { search, setSearch };
};
