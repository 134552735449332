import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useJwtUser } from '~/app/providers/authProvider';
import { handleProfile } from '~/entities/user/common/model';
import { emitEvent, events } from '~/shared/events';

/**
 * @returns {{accountInfo: Account}}
 */
export const useAccountInfo = () => {
  const { setUser } = useJwtUser();

  const {
    data: accountInfo,
    isLoading,
    refetch,
  } = useQuery(['account'], async () => await handleProfile(), {
    keepPreviousData: false,
    staleTime: 1000,
  });

  useEffect(() => {
    if (accountInfo && !isLoading) {
      setUser(accountInfo);
      setTimeout(() => emitEvent(events.endLoaderEvent), 300);
    }
  }, [accountInfo, isLoading]);

  return { refetch };
};
