import { useCallback, useContext } from 'react';

import { ConfigContext } from '~/app/providers/configProvider';
import { useEvent } from '~/shared/hooks';

/**
 * @typedef {Object} ConfigItem
 * @property {string} key
 * @property {[Lookup]} values
 */

export const useDictionaryConfig = () => {
  const { getConfigValues, addToConfig } = useContext(ConfigContext);

  const getByKey = useCallback((key) => getConfigValues(key), []);
  const getByKeyAndValue = useCallback(
    (key, value) => getByKey(key).find((dictionary) => dictionary.value === value),
    []
  );

  const addManyToConfig = useCallback((configs) => {
    Object.entries(configs).forEach(([key, values]) => addToConfig({ key, values }));
  }, []);

  return { getByKey, addToConfig, addManyToConfig, getByKeyAndValue };
};
