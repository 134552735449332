import React, { useContext, useEffect, useMemo, useRef } from 'react';

import { useJwtUser } from '~/app/providers/authProvider';
import { LayoutContext } from '~/app/providers/layoutProvider';
import { useLoading } from '~/app/providers/loaderProvider';
import { useLoadLookups } from '~/entities/lookups';
import { useAccountInfo } from '~/entities/user';
import { useAuth } from '~/processes/auth';
import {
  CAN_ALLOW_LISTING,
  CAN_CREATE_LISTING,
  CAN_EDIT_PROFILE,
  IS_BUYER_ROLE,
  IS_SELLER_ROLE,
} from '~/processes/auth/permissions';
import { useWithUserApiPrefix } from '~/shared/acl';
import { Container, icons } from '~/shared/components';
import { LoadingSpinner, MenuBody, MenuContainer, MenuFooter, MenuHeader, MenuItems } from '~/shared/components';
import { CommonSubscribers } from '~/shared/events';
import { NotificationBlock } from '~/widgets/notification';

// eslint-disable-next-line react/display-name
const LayoutMemoized = React.memo(({ children }) => {
  const containerRef = useRef(null);
  const menuContentRef = useRef(null);
  const contentRef = useRef(null);
  const { user } = useJwtUser();
  const { prefix } = useWithUserApiPrefix();
  const { setMainContainer } = useContext(LayoutContext);

  useEffect(() => {
    if (containerRef.current) setMainContainer(containerRef.current);
  }, [containerRef?.current]);

  const menuItems = useMemo(
    () => [
      {
        to: `/${prefix}/marketplace`,
        icon: icons.Home,
        label: 'Marketplace',
        permissions: [IS_SELLER_ROLE, IS_BUYER_ROLE],
      },
      {
        to: `/${prefix}/favorites`,
        icon: icons.Bookmark,
        label: 'Favorites',
        permissions: [IS_BUYER_ROLE],
        noFullPermissions: [CAN_EDIT_PROFILE],
      },
      {
        to: `/${prefix}/matches`,
        icon: icons.Tokens,
        label: 'Matches',
        permissions: ['HIDE'],
        noFullPermissions: [],
      },
      {
        to: `/${prefix}/deals`,
        icon: icons.Backpack,
        label: 'Pursued',
        permissions: [IS_BUYER_ROLE],
        noFullPermissions: [],
      },
      {
        to: `/${prefix}/own-listings`,
        icon: icons.Bookmark,
        label: 'My Listings',
        permissions: [IS_SELLER_ROLE],
      },
      {
        to: `/${prefix}/prospects`,
        icon: icons.QuestionMark,
        label: 'Prospects',
        permissions: ['HIDE'],
        noFullPermissions: [],
      },
    ],
    [prefix, user]
  );

  const footerMenuItems = useMemo(
    () => [
      { to: `/${prefix}/profile`, icon: icons.Person, label: 'Account Settings', permissions: [CAN_EDIT_PROFILE] },
      {
        to: `/${prefix}/preferences`,
        icon: icons.Gear,
        label: 'Buyer Preferences',
        permissions: ['HIDE'],
        noFullPermissions: [],
      },
      // { to: `/${prefix}/subscriptions`, icon: icons.Reader, label: 'Subscriptions', permissions: [] },
      {
        to: '/auth/sign-out',
        icon: icons.Exit,
        label: 'Sign Out',
        permissions: [],
      },
    ],
    [prefix, user]
  );

  return (
    <Container className={'container container-menu'} ref={containerRef}>
      <MenuContainer
        header={<MenuHeader menuContentRef={menuContentRef} />}
        body={<MenuBody ref={menuContentRef} accountInfo={user} menuItems={<MenuItems items={menuItems} />} />}
        footer={<MenuFooter menuItems={<MenuItems items={footerMenuItems} />} />}
      />
      <main className="content" ref={contentRef}>
        <>
          <NotificationBlock elementTo={contentRef.current} className={'notification-list notification-list-show'} />
          {children}
        </>
      </main>
    </Container>
  );
});

export const PrivateLayout = ({ children }) => {
  const { syncJwt } = useAuth();
  useAccountInfo();
  useLoadLookups();

  useEffect(() => {
    const timeout = setInterval(() => syncJwt(), 30 * 1000);
    return () => clearInterval(timeout);
  }, []);

  const { showLoader } = useLoading();
  return (
    <>
      <CommonSubscribers />
      {showLoader ? <LoadingSpinner fullPage={true} /> : <LayoutMemoized>{children}</LayoutMemoized>}
    </>
  );
};
