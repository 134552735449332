import { applicationSettings } from '~/shared/application.settings';
import { emitEvent } from '~/shared/events';
import { events } from '~/shared/events/handlers/common/events';
import axios from 'axios';
import { camelizeKeys, decamelize, decamelizeKeys } from 'humps';

const DEFAULT_REQUEST_TIMEOUT = 150000;
const DEFAULT_CONTENT_TYPE = 'application/json';

/**
 * Ger current browser timezone
 * @returns {boolean|string}
 */
const getTimeZone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (Exception) {
    return false;
  }
};

export const api = axios.create({
  baseURL: applicationSettings.API_ENDPOINT,
  timeout: DEFAULT_REQUEST_TIMEOUT,
  headers: {
    Accept: 'application/json',
    'Content-Type': DEFAULT_CONTENT_TYPE,
    'x-gatsby-app': Math.random(),
    Timezone: getTimeZone(),
  },
  withCredentials: true,
});

const provideRequestInterception = (callback, handler) => {
  api.interceptors.request.use(callback, handler);
};

const provideResponseInterception = (callback, handler) => {
  api.interceptors.response.use(callback, handler);
};

export const provideJWTHeader = (jwtToken) => {
  delete api.defaults.headers.common['Authorization'];
  jwtToken ? (api.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`) : null;
};

const camelizeRequestInterceptor = (config) => {
  if (config.headers['Content-Type'] === 'multipart/form-data') return config;
  if (config.params) {
    config.params = decamelizeKeys(config.params);
  }
  if (config.data) {
    config.data = decamelizeKeys(config.data);
  }
  if (config.url) {
    config.url = decamelize(config.url);
  }
  return config;
};

const decamelizeRequestInterceptor = ({ data, config: { method = null } }) => {
  data = camelizeKeys(data);

  if (method === 'post') {
    return data;
  }
  return data;
};
provideRequestInterception(camelizeRequestInterceptor, (error) => Promise.reject(error));
provideResponseInterception(decamelizeRequestInterceptor, (error) => {
  if (error.response?.status === 401) {
    emitEvent(events.logoutEvent);
    return;
  }
  if (error.response?.status === 404) {
    emitEvent(events.notFoundEvent);
    return;
  }
  if (error.response?.status === 422) {
    return Promise.resolve(camelizeKeys(error?.response?.data));
  }
  return Promise.reject(camelizeKeys(error?.response?.data));
});
