import React, { createContext, useContext, useEffect, useLayoutEffect, useMemo, useReducer } from 'react';

const LoaderContext = createContext({});

export const ADD_LOADER = Symbol.for('add_loader').toString();
export const REMOVE_LOADER = Symbol.for('remove_loader').toString();

export const LoaderProvider = ({ children }) => {
  const updateLoader = (state, action) => {
    switch (action.type) {
      case ADD_LOADER:
        return {
          count: state.count + 1,
        };
      case REMOVE_LOADER:
        return {
          count: state.count - 1,
        };
    }
  };
  const [state, dispatch] = useReducer(updateLoader, { count: 1 }, () => ({ count: 1 }));
  const showLoader = useMemo(() => state.count > 0, [state]);
  return <LoaderContext.Provider value={{ showLoader, dispatch }}>{children}</LoaderContext.Provider>;
};

export const useLoading = () => {
  const { showLoader, dispatch } = useContext(LoaderContext);
  return { showLoader, dispatch };
};
