import React, { useContext } from 'react';
import { createContext, useState } from 'react';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  return <AuthContext.Provider value={{ jwt: useState(null), user: useState(null) }}>{children}</AuthContext.Provider>;
};

export const useJwtAuth = () => {
  const {
    jwt: [token, setToken],
  } = useContext(AuthContext);
  return { token, setToken };
};
/**
 *
 * @returns {{setUser: function, user: Account}}
 */
export const useJwtUser = () => {
  const {
    user: [user, setUser],
  } = useContext(AuthContext);
  return { user, setUser };
};
