import React from 'react';

/**
 * @param {(1|2|3|4|5)} variant
 * @param {string} className
 * @param {React.ReactNode} children
 * @param title
 * @returns {JSX.Element}
 * @constructor
 */
export const Heading = ({ variant, className, children, title = '' }) => {
  const Tag = `h${variant}`;
  return (
    <Tag className={className} title={title}>
      {children}
    </Tag>
  );
};
