import React from 'react';

import { Container } from '~/shared/components';
import clsx from 'clsx';

export const TileContainer = ({ children, classModifier = null, ...props }) => (
  <Container className={clsx('tile', classModifier && classModifier)} {...props}>
    {children}
  </Container>
);
