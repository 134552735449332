import React from 'react';

import { useQuery } from '@tanstack/react-query';

/**
 * @param {function} action
 * @param {string} operation
 */
export const useRetrieve = ({ action, operation }) => {
  const {
    data: retrieveResult,
    isLoading,
    isFetching,
    refetch,
  } = useQuery([operation], action, {
    initialData: {},
    placeholderData: {},
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  return { retrieveResult, isLoading, isFetching, refetch };
};
