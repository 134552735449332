import React from 'react';

import clsx from 'clsx';
import { Link } from 'gatsby';

/**
 * @param {React.ReactNode} children
 * @param {string} to
 * @param {string} defaultClassName
 * @param {string} activeClassName
 * @returns {JSX.Element}
 * @constructor
 */
export const HeaderLink = ({
  children,
  to,
  defaultClassName = 'header__link button button-primary',
  activeClassName = 'active',
}) => (
  <nav>
    <Link
      className={clsx(defaultClassName)}
      to={to}
      getProps={({ isCurrent, isPartiallyCurrent }) => ({
        className: clsx(defaultClassName, (isCurrent || isPartiallyCurrent) && activeClassName),
      })}
    >
      {children}
    </Link>
  </nav>
);
