import React from 'react';

import { useHasPermission } from '~/shared/acl';

/**
 * @param {React.ReactNode} Component
 * @param {[string]} permissions
 * @returns {JSX.Element}
 * @constructor
 */
export const NavigationGuard = ({ children, permissions, render }) => {
  const hasPermission = useHasPermission(permissions);
  if (!permissions.length || hasPermission()) return <>{children}</>;
  return <></>;
};
