import React from 'react';

import {
  deleteJwt,
  getJwt,
  getUserPermissions,
  isTokenExpired,
  setJwt,
  setJwtRefresh,
  storeTokens,
} from '~/processes/auth/jwt/model';

export const useJWTStorage = () => ({
  getJwt,
  setJwt,
  setJwtRefresh,
  getUserPermissions,
  isTokenExpired,
  deleteJwt,
  storeTokens,
});
