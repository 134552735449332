import React, { useCallback } from 'react';

import { useBuyerAccess, useWithUserApiPrefix } from '~/shared/acl';
import { navigate } from 'gatsby';

export const useNavigate = () => {
  const isBuyerInvoke = useBuyerAccess();
  const getPrefix = () => (isBuyerInvoke() ? 'buyer' : 'seller');
  const pathWithPrefix = (path) => `/${getPrefix()}/${path}`;
  const navigateWithPrefix = useCallback((path) => navigate(pathWithPrefix(path)), []);
  return { navigate: navigateWithPrefix, pathWithPrefix };
};
