import React from 'react';

import { Container } from '~/shared/components';
import clsx from 'clsx';

// eslint-disable-next-line react/display-name
export const LoadingSpinner = React.memo(({ isLoading, fullPage = false }) => {
  if (!isLoading) return <></>;
  return (
    <Container className={clsx('spinner-container', fullPage ? 'spinner-container-full' : '')}>
      <Container className="loading-spinner" />
    </Container>
  );
});

LoadingSpinner.defaultProps = {
  isLoading: true,
};
