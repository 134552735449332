import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { LayoutContext } from '~/app/providers/layoutProvider';
import defaultLogo from '~/assets/images/logo.svg';
import { Container, icons } from '~/shared/components';
import { Image } from '~/shared/components/ui/image';
import { useClickOutside, useDebounce } from '~/shared/hooks';
import clsx from 'clsx';
import noop from 'lodash.noop';

// eslint-disable-next-line react/display-name
const MenuBurger = React.forwardRef(({ onClick }, ref) => (
  <Container ref={ref} className="menu__burger" onClick={onClick}>
    <span className="menu__burger-line"></span>
    <span className="menu__burger-line"></span>
    <span className="menu__burger-line"></span>
  </Container>
));

const TabletSearch = ({ setSearchIsActive, search, isActive }) => {
  const searchInputRef = useRef(null);
  const [searchValue, setSearchValue] = useState(null);
  const searchValueDebounced = useDebounce(searchValue, 300);

  useEffect(() => {
    search.updateSearch({ value: searchValueDebounced });
  }, [searchValueDebounced]);

  useEffect(() => {
    if (searchInputRef?.current) {
      searchInputRef.current.value = '';
      searchInputRef.current.focus();
    }

    return () => {
      search.updateSearch({ value: '' });
    };
  }, [searchInputRef?.current]);

  const onCloseSearch = () => {
    setSearchValue(null);
    setSearchIsActive(false);
    search.updateSearch({ value: searchValueDebounced });
  };

  return (
    <Container className="search__wrapper search__tablet">
      {isActive ? (
        <input
          ref={searchInputRef}
          autoFocus
          type="text"
          placeholder="Search"
          className="search__input"
          onChange={(event) => setSearchValue(event.target.value)}
        />
      ) : (
        <></>
      )}
      <Container className="search__icon" onClick={() => setSearchIsActive(true)}>
        <Image src={icons.SearchTablet} />
      </Container>
      <Container className="search__icon-close" onClick={onCloseSearch}>
        <Image src={icons.CloseSearch} />
      </Container>
    </Container>
  );
};

export const MenuHeader = ({ logo = defaultLogo, className = 'menu__header', menuContentRef }) => {
  const { search } = useContext(LayoutContext);
  const burgerRef = useRef(null);
  const [_, setMenuIsToggled] = useState(false);
  const { setAction } = useClickOutside({ elementRef: burgerRef });

  const [searchIsActive, setSearchIsActive] = useState(false);

  const isActive = useMemo(() => searchIsActive && !!search, [searchIsActive, search]);

  useEffect(() => {
    if (!search) {
      setSearchIsActive(false);
    }
  }, [search]);

  const toggleMenu = () => {
    setMenuIsToggled((prev) => {
      burgerRef.current?.classList.toggle('active');
      menuContentRef.current?.parentElement.classList.toggle('show');
      setAction(!prev ? () => () => toggleMenu() : () => () => {});
      return !prev;
    });
  };
  return (
    <Container className={clsx(className, isActive ? 'search-active' : null)}>
      <Image alt="logo" src={logo} className="menu__logo" />
      {search ? <TabletSearch setSearchIsActive={setSearchIsActive} search={search} isActive={isActive} /> : null}
      <MenuBurger ref={burgerRef} onClick={toggleMenu} />
    </Container>
  );
};
