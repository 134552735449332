/**
 * @param {string} eventName
 * @param {function} listener
 */
const subscribe = (eventName, listener) => {
  if (typeof window === `undefined`) return;
  document.addEventListener(eventName, listener);
};

/**
 * @param {string} eventName
 * @param {function} listener
 */
const unsubscribe = (eventName, listener) => {
  if (typeof window === `undefined`) return;
  document.removeEventListener(eventName, listener);
};

/**
 * @param {string} eventName
 * @param {Object?} data
 */
const emitEvent = (eventName, data) => {
  if (typeof window === `undefined`) return;
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
};

export { events } from './handlers/common/events';
export { CommonSubscribers } from './handlers/common/common-subscribers';
export { emitEvent, subscribe, unsubscribe };
