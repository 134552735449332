import React from 'react';

import { useMutation } from '@tanstack/react-query';

/**
 * @param {function} action
 * @param {function} onSuccess
 * @param {function} onError
 * @param {string} formName
 * @returns {UseMutationResult<unknown, unknown, void, unknown>}
 */
export const useUpdate = ({ action, onSuccess = () => {}, onError = (error, _) => true, operation }) => {
  const { mutateAsync, isLoading, error } = useMutation(action, {
    mutationKey: operation,
    onSuccess: (data, variables, context) => {
      if (data.statusCode === 422) {
        console.warn('already complete');
        return;
      }
      onSuccess(data, variables, context);
    },
    onError: (error, variables) => onError(error, variables),
    useErrorBoundary: (error) => error?.statusCode || 500 >= 500,
    retry: 0,
    throwOnError: false,
  });
  return { mutate: mutateAsync, isLoading, error };
};
