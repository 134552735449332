import React from 'react';

import { PrivateLayout } from '~/widgets/layout/private/layout';
import { PublicLayout } from '~/widgets/layout/public_layout/layout';

// eslint-disable-next-line react/display-name
export default ({ children, pageContext, location }) => {
  if (pageContext.layout === 'public') {
    return <PublicLayout location={location}>{children}</PublicLayout>;
  }
  if (pageContext.layout === 'seller' || pageContext.layout === 'buyer') {
    return <PrivateLayout location={location}>{children}</PrivateLayout>;
  }
  return <>{children}</>;
};
