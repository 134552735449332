import React, { createContext, useCallback, useState } from 'react';

import { useEvent } from '~/shared/hooks';

export const ConfigContext = createContext(null);

const initialConfig = {};

export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(initialConfig);

  const addToConfig = useCallback(
    ({ key, values }) =>
      setConfig((prevConfig) => {
        prevConfig[key] = values;
        return prevConfig;
      }),
    []
  );

  const getConfigValues = (key) => config?.[key] || [];

  return (
    <ConfigContext.Provider value={{ addToConfig, getConfigValues, config }} displayName="Context Display Name">
      {children}
    </ConfigContext.Provider>
  );
};
