import { provideJWTHeader } from '~/shared/api';
import decode from 'jwt-decode';

const JWT_STORAGE_KEY = 'AUTH_JWT';
const JWT_REFRESH_STORAGE_KEY = 'REFRESH_JWT';

export const getJwt = () => {
  if (typeof window === `undefined`) return undefined;
  return JSON.parse(localStorage.getItem(JWT_STORAGE_KEY));
};
export const getJwtRefresh = () => {
  if (typeof window === `undefined`) return undefined;
  return JSON.parse(localStorage.getItem(JWT_REFRESH_STORAGE_KEY));
};
export const setJwt = (jwt) => localStorage.setItem(JWT_STORAGE_KEY, JSON.stringify(jwt));
export const setJwtRefresh = (jwt) => localStorage.setItem(JWT_REFRESH_STORAGE_KEY, JSON.stringify(jwt));

export const deleteJwt = () => {
  if (typeof window === `undefined`) return undefined;
  localStorage.removeItem(JWT_STORAGE_KEY);
  localStorage.removeItem(JWT_REFRESH_STORAGE_KEY);
  provideJWTHeader(null);
};

export const isTokenExpired = (token) => (token ? decode(token).exp <= Date.now() / 1000 : true);

export const setAuthToken = (authToken) => {
  const jwt = getJwt();
  jwt && setJwt({ ...jwt, authToken });
};

export const getUserPermissions = () => {
  const jwt = getJwt();
  return jwt ? decode(getJwt())?.permissions.map((permission) => permission.split('.')[1]) || [] : [];
};

export const storeTokens = ({ accessToken, refreshToken }) => {
  setJwt(accessToken);
  setJwtRefresh(refreshToken);
  return Promise.resolve();
};
