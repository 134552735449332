import React, { useContext, useRef } from 'react';
import { createContext, useState } from 'react';

export const LayoutContext = createContext(null);

export const LayoutProvider = ({ children }) => {
  const [mainContainer, setMainContainer] = useState(null);
  const [search, setSearch] = useState(null);
  return (
    <LayoutContext.Provider value={{ mainContainer, setMainContainer, search, setSearch }}>
      {children}
    </LayoutContext.Provider>
  );
};
