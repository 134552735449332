import React, { useEffect } from 'react';

import { useJwtAuth } from '~/app/providers/authProvider';
import { useJWTStorage } from '~/processes/auth/jwt/hooks/useJWTStorage';
import { getJwtRefresh } from '~/processes/auth/jwt/model';
import { refreshTokenAction } from '~/processes/auth/model';
import { provideJWTHeader } from '~/shared/api';
import isEqual from 'lodash.isequal';

export const useAuth = () => {
  const { token, setToken } = useJwtAuth();
  const { getJwt, setJwt, storeTokens, ...restJwt } = useJWTStorage();
  const syncJwt = () => {
    if (typeof window === `undefined`) return;

    provideJWTHeader(getJwt());
    const jwtInLocalStorage = getJwt();
    refreshAvailableToken().then((r) => {});

    if (!jwtInLocalStorage || isEqual(token, jwtInLocalStorage)) {
      return;
    }
    provideJWTHeader(getJwt());
    setToken(getJwt());
  };

  useEffect(syncJwt, []);

  const refreshAvailableToken = async () => {
    const refreshToken = getJwtRefresh();
    if (!refreshToken) return;
    const data = await refreshTokenAction({ token: refreshToken });
    if (data) {
      storeTokens({ accessToken: data.access, refreshToken: data.refresh });
    }
  };

  return { isLoggedIn: !!getJwt(), syncJwt, getJwt, ...restJwt };
};
